<template>
  <div class="index">
    <Carousel :list="lbImgs"></Carousel>
    <div class="index-shoji-youshi">
      <ul class="shoji-youshi-bg">
        <li v-for="(item, idx) in youshiList" :key="idx">
          <p>
            <span>{{ item.key }}</span>
            {{ idx == 0 ? '年' : idx == 1 ? '家' : idx == 2 ? '项' : '时' }}
          </p>
          <h3>{{ item.value }}</h3>
        </li>
      </ul>
    </div>
    <!-- 我们的服务 -->
    <div class="index-fwxm">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          我们的服务
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">SERVIES</span>
        </p>
        <p class="index-title-2">
          我们专注于网站建设、微营销、网站推广等各种网络营销产品
        </p>
      </div>
      <ul class="container index-fwxm-container">
        <li v-for="(item, idx) in fwList" :key="idx">
          <span>
            <i :style="{ background: `url(${item.icon_image}) 0 0` }"></i>
          </span>
          <span>{{ item.server }}</span>
          <span v-for="(el, i) in item.servertags" :key="i" class="type_a">
            {{ el }}
          </span>
        </li>
      </ul>
      <ul class="container index-fwxm-container2">
        <div class="container2_nav">
          <li v-for="(item, idx) in fwList" :key="idx" :class="'nav' + idx">
            <img :src="item.mobile_icon_image" alt="" />
            <h3>{{ item.server }}</h3>
            <p>{{ item.fu_title }}</p>
          </li>
        </div>
      </ul>
    </div>
    <!-- 域名查询 -->
    <!-- <div class="index-fwxm index-yuming">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          域名查询
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">DOMAIN</span>
        </p>
        <p class="index-title-2">
          别犹豫，让你的域名成为下一个互联网奇迹(由阿里云提供支持注册服务)
        </p>
      </div>
      <div class="index-ymcx">
        <div class="index-ymcx-1">
          <el-input
            placeholder="请输入域名，如zhenyi然后点击右侧按钮直接查询"
            v-model="ymText"
            class="input-with-select"
            style="width:100%"
          >
            <el-button slot="append">查域名</el-button>
          </el-input>
        </div>
        <div class="index-ymcx-2">
          <div class="user-sex" v-for="(item, idx) in yumingList" :key="idx">
            <el-checkbox
              class="user-sex-list"
              v-model="item.status"
              :label="item.suffix"
              border
            ></el-checkbox>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 模板建站 -->
    <div class="index-fwxm">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          模板建站
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">TEMPLATE</span>
        </p>
        <p class="index-title-2">
          拥有海量精美网站模板，帮助企业快速搭建网站，打开网络营销之门
        </p>
      </div>
      <div class="index-moban">
        <ul>
          <li v-for="(item, idx) in mobanList" :key="idx">
            <div class="moban_content">
              <a :href="item.link" target="_blank">
                <div class="moban_content_tp">
                  <img
                    src="~@/assets/image/moban/moban-pc-bg.png"
                    alt="背景图"
                    class="list_img"
                  />
                  <img :src="item.site_images" alt="" class="list_img2" />
                  <div class="list_yl">
                    <div class="yulan-an centerText">
                      <div class="yulan-an-logo">
                        <img
                          src="@/assets/image/svg/yulan.svg"
                          alt=""
                          style="width:100%;height:100%"
                        />
                      </div>
                      <span>预览</span>
                    </div>
                  </div>
                </div>
                <div class="moban_sj_content_tp">
                  <img
                    :src="item.site_images"
                    :alt="item.name"
                    style="width:100%"
                  />
                </div>
              </a>
              <div class="moban_content_text">
                <div class="text_upper">
                  <div class="text_upper_left">
                    <p style="margin-bottom: 8px;">名称：{{ item.title }}</p>
                    <p style="font-size: 12px;color: #959595;">
                      当前编号：XF_{{ item.id >= 10 ? item.id : '0' + item.id }}
                    </p>
                  </div>
                  <div class="text_upper_right centerText">
                    ￥{{ item.price }}元
                  </div>
                </div>
                <div class="text_lower">
                  <div>
                    <span>适用设备：</span>
                    <img src="@/assets/image/svg/sanheyi.svg" alt="" />
                    <span style="color: #ec393b;">
                      {{
                        item.compatible == 0 ? '小程序模板' : '网站模板'
                      }}</span
                    >
                  </div>
                  <p style="font-size: 12px;color: #959595;">
                    浏览次数：{{ item.reads }}
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <a href="#/moban" class="moban_a centerText"
        ><span
          >查看更多模板&nbsp;<i class="el-icon-arrow-right"></i
          ><i class="el-icon-arrow-right"></i></span
      ></a>
    </div>
    <!-- 优势 -->
    <div
      class="index-youshi"
      :style="{
        background: `#272826 url(${youshiBg}) 100%/100% no-repeat`
      }"
    >
      <div class="container index-youshi-auto">
        <div
          class="index-youshi-list"
          v-for="(item, idx) in youshiList"
          :key="idx"
        >
          <p style="margin-bottom:10px">
            <span>{{ item.key }}</span>
            {{ idx == 0 ? '年' : idx == 1 ? '家' : idx == 2 ? '项' : '时' }}
          </p>
          <p>{{ item.value }}</p>
        </div>
      </div>
    </div>
    <!-- 精品案例 -->
    <div class="index-fwxm">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          精品案例
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">CASES</span>
        </p>
        <p class="index-title-2">
          多年网站建设经验，我们已帮助逾5000家客户走上网络营销之路
        </p>
      </div>
      <div class="index-jpal">
        <ul>
          <li v-for="(item, idx) in alList" :key="idx">
            <a :href="'#/works?currentId=' + item.id" @click="toLink">
              <div class="index-jpal-cong">
                <div class="index-jpal-cong-img">
                  <div class="index-jpal-cong-img-fd">
                    <div class="img-ico">
                      <i
                        class="el-icon-caret-right"
                        style="font-size:35px;color:#fff"
                      ></i>
                    </div>
                  </div>
                  <img
                    style="width: 100%;"
                    :src="item.site_images"
                    :alt="item.title + '网站作品'"
                  />
                </div>
                <div class="index-jpal-cong-nr">
                  <h4>
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </h4>
                  <div class="cong-ico">
                    <i class="el-icon-monitor" style="font-size:25px"></i
                    ><i class="el-icon-mobile-phone" style="font-size:22px"></i>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <a href="#/works" class="moban_a centerText"
          >查看更多模板&nbsp;<i class="el-icon-arrow-right"></i
          ><i class="el-icon-arrow-right"></i
        ></a>
      </div>
    </div>
    <!-- 新闻动态 -->
    <div class="index-fwxm index-xwdt">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          新闻动态
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">NEWS</span>
        </p>
        <p class="index-title-2">
          观察行业视觉，用我们专业的角度，讲出你们的心声
        </p>
      </div>
      <ul class="index-xwdt-title" v-if="xwdtList.length != 0">
        <li
          v-for="(item, idx) in xwdtList"
          :key="idx"
          :class="[xwActive == idx ? 'xw_xz_style' : '']"
          @mouseover="xwActive = idx"
          @click="xwActive = idx"
        >
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <ul class="container index-xwdt-nr" v-if="xwdtList.length != 0">
        <li v-for="(item, idx) in xwdtList" :key="idx" v-show="xwActive == idx">
          <a
            class="xwdt-li-left"
            v-if="item.tuijian"
            :href="
              `#/trends?detailsId=${item.tuijian.id}&currentIdx=${idx + 1}`
            "
          >
            <div class="xwdt-li-left-img zz_all_img">
              <img
                :src="H5_IMG_HOST + item.tuijian.cover_image"
                alt="网站定制一般要多少钱？定制网站有哪些优势？"
                style="width:100%;height:100%;transition: 0.5s;"
              />
            </div>
            <div class="xwdt-li-left-db">
              <div class="db-left">
                <h5>
                  <i></i>
                  {{ item.tuijian.title }}
                </h5>
                <p>
                  {{ item.tuijian.miaoshu }}
                </p>
                <span>{{ item.tuijian.create_time }}</span>
              </div>
              <div class="db-right">
                <span class="day">{{
                  item.tuijian.create_time.slice(8, 10)
                }}</span>
                <span>{{ item.tuijian.create_time.slice(0, 7) }}</span>
              </div>
            </div>
          </a>
          <ul class="xwdt-li-right">
            <li v-for="(el, i) in item.news" :key="i">
              <a :href="`#/trends?detailsId=${el.id}&currentIdx=${idx + 1}`">
                <div class="xwdt-li-right-le">
                  <span class="xwdt-li-right-le-span">{{
                    el.create_time.slice(8, 10)
                  }}</span>
                  <span>{{ el.create_time.slice(0, 7) }}</span>
                </div>
                <div class="xwdt-li-right-ri">
                  <h5>{{ el.title }}</h5>
                  <p>
                    {{ el.miaoshu }}
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- 服务流程 -->
    <div class="index-fwxm index-fwlc">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          服务流程
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">PROCESS</span>
        </p>
        <p class="index-title-2">
          千里之行，始于足下 / 迈出建站第一步，从联系我们开始
        </p>
      </div>
      <ul class="index-liucheng container">
        <li v-for="(item, idx) in lcList" :key="idx">
          <span class="liucheng-img iconweixin1 centerText">
            <div>
              <img
                :src="item.icon_image"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
          </span>
          <p class="index-liucheng-1">{{ item.server }}</p>
          <p class="index-liucheng-2">{{ item.fu_title }}</p>
        </li>
      </ul>
    </div>
    <!-- <div class="index-fwxm index-hzhb">
      <div class="index-fwxm-title">
        <p class="index-title-1">
          合作伙伴
          <span class="index-title-1-i1">·</span>
          <span class="index-title-1-i2">NEWS</span>
        </p>
      </div>
      <Carousel :list="hzhbList" :status="2"></Carousel>
    </div> -->
  </div>
</template>
<script>
import Head from '@/common/head/index-head.vue'
import Carousel from '@/common/Carousel/Carousel.vue'
import {
  getMenuBanners,
  getXwData,
  getMobanList,
  getCaseList,
  getServersList,
  getPageMsg,
  getBasicMsg
} from '@/api/api'
export default {
  components: {
    Head,
    Carousel
  },
  data () {
    return {
      lbImgs: [],
      fwList: [],
      ymText: '',
      yumingList: [
        {
          status: true,
          suffix: '.com'
        },
        {
          status: true,
          suffix: '.cn'
        },
        {
          status: true,
          suffix: '.net'
        },
        {
          status: true,
          suffix: '.com.cn'
        },
        {
          status: true,
          suffix: '.net.cn'
        },
        {
          status: false,
          suffix: '.club'
        },
        {
          status: true,
          suffix: '.top'
        },
        {
          status: false,
          suffix: '.site'
        },
        {
          status: false,
          suffix: '.xyz'
        },
        {
          status: false,
          suffix: '.org'
        },
        {
          status: false,
          suffix: '.info'
        },
        {
          status: false,
          suffix: '.vip'
        },
        {
          status: false,
          suffix: '.xin'
        },
        {
          status: false,
          suffix: '.wang'
        },
        {
          status: false,
          suffix: '.online'
        }
      ],
      mobanList: [],
      youshiList: [],
      youshiBg: '',
      alList: [],
      xwdtList: [],
      xwActive: 0,
      lcList: [],
      // hzhbList: [
      //   {
      //     imgUrl: [
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-1.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-3.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-5.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-2.jpg'
      //     ]
      //   },
      //   {
      //     imgUrl: [
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-4.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-6.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-3.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-5.jpg'
      //     ]
      //   },
      //   {
      //     imgUrl: [
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-7.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-4.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-6.jpg',
      //       'http://www.zhenyijz.com/tpl/www_mobile/images/hzhv-8.jpg'
      //     ]
      //   }
      // ],
      top: '',
      navShow: false,
      headBg: false,
      screenWidth: null
    }
  },
  methods: {
    getData () {
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          // this.changeData() = item.data.data.project_code
          let id = item.data.data.menu_id
          let project_id = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              this.youshiList = res.data.data.优势.ysList
              this.youshiBg = res.data.data.优势.youshiBg
            }
          })
          getXwData(project_id).then(res => {
            if (res.data.code == 1) {
              this.xwdtList = res.data.data
            }
          })
          getCaseList(1, '', project_id).then(res => {
            if (res.data.code == 1) {
              this.alList = res.data.data.list
            }
          })
        }
      })
      getMenuBanners(this.changeData()).then(res => {
        if (res.data.code == 1) {
          if (res.data.data.length != 0) {
            this.lbImgs = res.data.data
          }
        }
      })
      getMobanList(1, '').then(res => {
        if (res.data.code == 1) {
          this.mobanList = res.data.data.list
        }
      })
      getServersList().then(res => {
        if (res.data.code == 1) {
          this.fwList = res.data.data.server
          this.lcList = res.data.data.liucheng
        }
      })
      this.handleScroll()
    },
    handleScroll () {
      window.addEventListener('scroll', () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
      })
      this.screenWidth = document.body.clientWidth
      window.onresize = () => {
        //屏幕尺寸变化
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
    },
    toLink () {
      sessionStorage.setItem(this.changeData() + 'id', 12)
    }
  },
  mounted () {
    this.getData()
  },
  computed: {}
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.index {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .index-shoji-youshi {
    display: none;
    margin: 0.5rem;
    // margin-bottom: 1rem;
    .shoji-youshi-bg {
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        text-align: center;
        box-sizing: border-box;
        padding: 1rem 0;
        position: relative;
        p {
          font-size: 0.45rem;
          span {
            font-size: 1.2rem;
            color: #e62022;
          }
        }
        h3 {
          font-weight: normal;
          font-size: 0.42rem;
          color: #666;
          margin-top: 0.5rem;
        }
      }
      li:nth-child(1)::after,
      li:nth-child(3)::after {
        content: '';
        width: 1px;
        height: 60%;
        position: absolute;
        right: 0;
        top: 20%;
        background-color: #f2f2f2;
      }
      // li:nth-child(2)::after{
      //   content: "";
      //   width: 60%;
      //   height: 1px;
      //   position: absolute;
      //   right: 20%;
      //   bottom: 0;
      //   background-color: #f2f2f2;
      // }
    }
  }
  .index-fwxm {
    min-width: 1200px;
    padding: 35px 0 60px;
    .index-fwxm-title {
      padding: 30px 0;
      text-align: center;
      margin-bottom: 10px;
    }
    .index-fwxm-container,
    .index-fwxm-container2 {
      display: flex;
      li {
        width: 17%;
        text-align: center;
        min-height: 320px;
        position: relative;
        margin: 0 1.5%;
        span {
          display: block;
          font-size: 14px;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          color: #888888;
          i {
            display: block;
            width: 65px;
            height: 61px;
            margin: 40px auto;
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s;
          }
        }
        .type_a {
          padding: 6px 0;
        }
        span:nth-child(2) {
          display: block;
          margin-bottom: 10px;
          font-size: 18px;
          color: #292929;
          font-weight: bolder;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
        }
      }
      li:hover {
        span {
          color: #fff !important;
          i {
            background-position: 0 -61px !important;
          }
        }
      }
      li:hover:after {
        height: 100%;
      }
      li:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #e62022;
        z-index: -1;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
      }
    }
    .index-fwxm-container2 {
      display: none;
      li {
        width: 50%;
        border-right: 0.05rem #e7e7e7 solid;
        border-bottom: 0.05rem #e7e7e7 solid;
        box-sizing: border-box;
        padding: 1rem 0.5rem;
        min-height: auto;
        line-height: 1.5;
        margin: 0;
        img {
          width: 3rem;
        }
        h3 {
          font-size: 0.8rem;
          color: #181818;
          line-height: 2;
        }
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //两行，根据需求可更改
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.42rem;
          color: #999;
        }
      }
      .nav4 {
        display: none;
      }
    }
    .index-moban {
      padding: 28px 20px;
      min-height: 600px;
      margin: 0 20px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 25%;
          margin-bottom: 35px;
          .moban_content {
            padding: 0 5px;
            .moban_content_tp {
              position: relative;
              .list_img {
                width: 100%;
                position: relative;
                z-index: 9;
              }
              .list_img2 {
                position: absolute;
                left: 11.2%;
                top: 5.85%;
                width: 78%;
                z-index: 99;
                height: 87%;
              }
              .list_yl {
                position: absolute;
                left: 11.2%;
                top: 5.85%;
                bottom: 6.5%;
                width: 78%;
                z-index: 999;
                background-color: rgba(0, 0, 0, 0.2);
                display: flex;
                justify-content: center;
                flex-direction: column;
                opacity: 0;
                transition: 0.5s;
                .yulan-an {
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  background-color: rgba(255, 255, 255, 0.9);
                  flex-direction: column;
                  color: #ec393b;
                  margin: auto;
                  .yulan-an-logo {
                    width: 40px;
                    height: 40px;
                  }
                  span {
                    font-size: 14px;
                    margin-top: 3px;
                  }
                }
              }
              .list_yl:hover {
                opacity: 1;
              }
              .list_yl::after {
                content: '';
                height: 5%;
                background: #333;
                filter: blur(10px);
                position: absolute;
                left: 10%;
                right: 10%;
                bottom: 0;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border-radius: 100%;
              }
            }
            .moban_content_tp:after {
              content: '';
              height: 5%;
              background: #333;
              filter: blur(10px);
              position: absolute;
              left: 10%;
              right: 10%;
              bottom: 0;
              border-radius: 100%;
            }
            .moban_sj_content_tp {
              display: none;
              position: relative;
              border: 1px solid #ddd;
              overflow: hidden;
              img {
                height: 6rem;
              }
            }
            .moban_content_text {
              margin-top: 30px;
              padding: 0 10%;
              font-size: 14px;
              color: #666;
              .text_upper {
                display: flex;
                justify-content: space-between;
                .text_upper_left {
                  width: 60%;
                  p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .text_upper_right {
                  color: #ec393b;
                  padding: 0 12px;
                  border: 1px solid #ec393b;
                  border-radius: 5px;
                  cursor: pointer;
                  text-align: center;
                }
              }
              .text_lower {
                margin-top: 6px;
                color: #535353;
                font-size: 12px;
                line-height: 24px;
                display: flex;
                justify-content: space-between;
                i {
                  color: #ec393b;
                  font-size: 20px;
                  vertical-align: top;
                }
              }
            }
          }
        }
      }
    }
    .moban_a {
      height: 50px;
      border: 1px solid #ccc;
      color: #333;
      width: 230px;
      font-size: 16px;
      margin: auto;
      margin-top: 20px;
      transition: 0.3s;
    }
    .moban_a:hover {
      color: #fff;
      background-color: #e62022;
      border: 1px solid #e62022;
    }
  }
  .index-youshi {
    width: 100%;
    height: 240px;
    margin: 0 auto;
    .index-youshi-auto {
      display: flex;
      align-items: center;
      height: 100%;
      .index-youshi-list {
        text-align: center;
        color: #f7f7f7;
        width: 25%;
        position: relative;
        p {
          span {
            font-size: 50px;
          }
        }
      }
    }
  }
  .index-jpal {
    margin-top: 30px;
    margin-bottom: 45px;
    ul {
      width: calc(100% - -16px);
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 25%;
        margin-bottom: 16px;
        .index-jpal-cong {
          padding: 0 8px;
          .index-jpal-cong-img {
            position: relative;
            .index-jpal-cong-img-fd {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              -webkit-justify-content: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;
              .img-ico {
                background-color: rgba(230, 32, 34, 0.9);
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 75px;
                height: 75px;
                border-radius: 50%;
                transform: skew(30deg, 10deg);
                opacity: 0;
                transition: 0.5s;
              }
            }
          }
          .index-jpal-cong-nr {
            overflow: hidden;
            height: 60px;
            padding: 0 10px 0 10px;
            background-color: #f1f1f1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
              color: #000;
            }
            i {
              color: #666;
              margin: 0 5px;
            }
          }
        }
      }
      li:hover {
        .index-jpal-cong-img-fd {
          .img-ico {
            transform: skew(0deg, 0deg) !important;
            opacity: 1 !important;
          }
        }
        .index-jpal-cong-nr {
          background-color: #e62022 !important;
          transform: 1s;
          a {
            color: #fff !important;
          }
          i {
            color: #fff !important;
            margin: 0 5px;
          }
        }
      }
    }
  }
  .index-xwdt {
    background-color: #f5f5f5;
    .index-xwdt-title {
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        zoom: 1;
        span {
          display: inline-block;
          color: #535353;
          padding: 10px 28px;
        }
      }
      .xw_xz_style {
        background-color: #e62022 !important;
        span {
          color: #fff !important;
        }
      }
    }
    .index-xwdt-nr {
      margin-top: 45px;
      padding-bottom: 20px;
      li {
        display: flex;
        justify-content: space-between;
        .xwdt-li-left {
          width: 30%;
          background: #fff;
          padding: 2.5%;
          height: 100%;
          cursor: pointer;
          .xwdt-li-left-img {
            width: 100%;
            height: 290px;
            overflow: hidden;
            margin-bottom: 20px;
          }
          .xwdt-li-left-db {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            .db-left {
              width: 70%;
              h5 {
                display: block;
                font-size: 18px;
                color: #484848;
                margin-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                i {
                  display: inline-block;
                  width: 0;
                  height: 1px;
                  background: #e62022;
                  vertical-align: middle;
                }
              }
              h5:hover {
                color: #e62022 !important;
                i {
                  width: 20px !important;
                }
              }
              p {
                color: #818181;
                height: 44px;
                overflow: hidden;
                line-height: 1.6;
              }
              span {
                display: none;
              }
            }
            .db-right {
              padding: 10px 3%;
              background: #e62022;
              text-align: center;
              color: #fff;
              display: flex;
              flex-direction: column;
              .day {
                font-size: 38px;
              }
            }
          }
        }
        .xwdt-li-right {
          width: 63%;
          overflow: hidden;
          li {
            width: 43.7%;
            background: #ffffff;
            overflow: hidden;
            padding: 4% 3%;
            margin: 1px;
            float: left;
            a {
              display: flex;
              justify-content: space-between;
              width: 100%;
              .xwdt-li-right-le {
                color: #555555;
                text-align: center;
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                .xwdt-li-right-le-span {
                  font-size: 40px;
                }
              }
              .xwdt-li-right-ri {
                width: 79%;
                h5 {
                  color: #484848;
                  font-size: 16px;
                  margin-bottom: 10px;
                  display: block;
                  height: 22px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                p {
                  color: #818181;
                  overflow: hidden;
                  line-height: 1.5;
                  font-size: 14px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2; //两行，根据需求可更改
                  white-space: wrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
  .index-liucheng {
    margin-top: 30px;
    padding-bottom: 10px;
    display: flex;
    li {
      width: 20%;
      text-align: center;
      span:after {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        content: '';
        top: 0;
        left: 0;
        z-index: -1;
      }
      .liucheng-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: auto;
        color: #fff;
        transition: 1s;
        background-color: #e62022;
      }
    }
    li:hover span {
      transform: scale(0.86);
    }
    li:hover span:after {
      animation: yidongfangda 1.3s ease-out 75ms;
    }
  }
  .index-hzhb {
    display: none;
  }
}
/deep/.el-input__inner {
  height: 64px !important;
  line-height: 64px !important;
}
/deep/.el-input-group__append {
  padding-left: 30px;
  position: absolute;
  right: -0;
  top: 0;
  width: 14%;
  background: url(~@/assets/image/index/sousuo-ico2.png) no-repeat 18% 20px
    #e62022 !important;
  background-size: 32px 32px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  border: 0;
  span {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    font-style: italic;
  }
}
/deep/.el-checkbox__input {
  display: none;
}
/deep/.el-checkbox.is-bordered.is-checked {
  border-color: #e62022 !important;
  background: url(~@/assets/image/index/icon-right.png) no-repeat right bottom
    #fff;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}
.el-checkbox {
  background-color: #fff;
}
/deep/.el-input__inner:focus {
  border-color: #c0c4cc !important;
}
</style>
